export default [
  {
    path: '/member/list',
    name: 'memberList',
    component: () =>
      import(
        /* webpackChunkName: "memberList" */ '@/views/member/list/index.vue'
      ),
    meta: {
      title: '会员列表',
    },
  },
  {
    path: '/member/dummy',
    name: 'memberDummy',
    component: () =>
      import(
        /* webpackChunkName: "memberDummy" */ '@/views/member/list/index.vue'
      ),
    meta: {
      title: '会员列表',
    },
  },
  {
    path: '/member/online',
    name: 'memberOnline ',
    component: () =>
      import(
        /* webpackChunkName: "memberOnline" */ '@/views/member/list/index.vue'
      ),
    meta: {
      title: '在线会员列表',
    },
  },
  {
    path: '/member/grade',
    name: 'memberGrade',
    component: () =>
      import(
        /* webpackChunkName: "memberGrade" */ '@/views/member/grade/index.vue'
      ),
    meta: {
      title: '会员等级',
    },
  },
  {
    path: '/member/log',
    name: 'memberLog',
    component: () =>
      import(
        /* webpackChunkName: "memberGrade" */ '@/views/member/log/index.vue'
      ),
    meta: {
      title: '会员登录日志',
    },
  },
  {
    path: '/member/log/dummy',
    name: 'memberLogDummy',
    component: () =>
      import(
        /* webpackChunkName: "memberLogDummy" */ '@/views/member/log/index.vue'
      ),
    meta: {
      title: '会员登录日志',
    },
  },
  {
    path: '/member/couple/list',
    name: 'memberCouple',
    component: () =>
      import(
        /* webpackChunkName: "memberLogDummy" */ '@/views/member/couple/index.vue'
      ),
    meta: {
      title: '情侣账号',
    },
  },
]
